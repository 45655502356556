<template>
  <div>
    <p class="font-weight-bold">
      <CIcon name="cilChatBubble" />
      Danh sách Ghé Thăm
      <!-- <router-link
        :to="{ name: 'Visit', params: { customerId } }"
        title="Tạo ghé thăm cho KH này"
      >
        <CIcon name="cil-plus" custom-classes="c-icon c-icon-lg text-success" />
      </router-link> -->
      <a
        href="javascript:"
        title="Tạo ghé thăm cho KH này"
        @click="
          isCreating = true;
          isCare = false;
        "
      >
        <CIcon name="cil-plus" custom-classes="c-icon c-icon-lg text-success" />
      </a>
      và Chăm Sóc
      <a
        href="javascript:"
        title="Tạo CSKH cho KH này"
        @click="
          isCreating = true;
          isCare = true;
        "
      >
        <CIcon name="cil-plus" custom-classes="c-icon c-icon-lg text-warning" />
      </a>
      <!-- <router-link
        :to="{
          name: 'Visit',
          params: { customerId },
          query: { isCare: true },
        }"
        title="Tạo CSKH cho KH này"
      >
        <CIcon name="cil-plus" custom-classes="c-icon c-icon-lg text-warning" />
      </router-link> -->
    </p>
    <odata-table
      ref="lstCustVisit"
      url="odata/CustomerVisit"
      :filter="filter"
      expand="Product($select=Name), Agency($select=Name), Orders($count=true;$top=0)"
      sortBy="CreatedAt desc"
      :onData="processData"
      :pageSize="pageSize"
      :colSetting="{
        Action: {
          field: '',
          display: 'Thao tác',
          sortable: false,
          style: 'min-width: 50px',
        },
        CreatedAt: {
          field: 'CreatedAt',
          display: 'Ngày ghé',
          sortable: true,
          style: 'min-width: 150px',
        },
        BuyingExp: {
          field: 'BuyingExp',
          display: 'Trải nghiệm',
          sortable: true,
          style: 'min-width: 100px',
        },
        Source: {
          field: 'Source',
          display: 'Kênh liên hệ',
          sortable: true,
          style: 'min-width: 120px',
        },
        Interest: {
          field: 'Interest',
          display: 'Quan tâm',
          sortable: true,
          style: 'min-width: 120px',
        },
        Comments: {
          field: 'Comments',
          display: 'Nhận định',
          sortable: true,
          style: 'min-width: 120px',
        },
        Product: {
          field: 'Product',
          display: 'Sản phẩm',
          sortable: true,
          style: 'min-width: 120px',
        },
        PriceRange: {
          field: 'PriceRange',
          display: 'Mức giá quan tâm',
          sortable: true,
          style: 'min-width: 120px',
        },
        NextStep: {
          field: 'NextStep',
          display: 'Kết quả ghé thăm',
          sortable: true,
          style: 'min-width: 120px',
        },
        Note: {
          field: 'Note',
          display: 'Ghi chú',
          sortable: true,
          style: 'min-width: 120px',
        },
        CreatedByName: {
          field: 'CreatedByName',
          display: 'Nhân viên',
          sortable: false,
          style: 'min-width: 100px',
        },
        Agency: {
          field: 'Agency/Name',
          display: 'Cửa hàng',
          sortable: true,
          style: 'min-width: 120px',
        },
        SaleProgram: {
          field: 'SaleProgram',
          display: 'Chương trình QC',
          sortable: true,
          style: 'min-width: 120px',
        },
        KOL: {
          field: 'KOL',
          display: 'KOL',
          sortable: true,
          style: 'min-width: 120px',
        },
        Advertise: {
          field: 'Advertise',
          display: 'ID Quảng cáo',
          sortable: true,
          style: 'min-width: 120px',
        },
        AdEvent: {
          field: 'AdEvent',
          display: 'Sự kiện QC',
          sortable: true,
          style: 'min-width: 120px',
        },
      }"
    >
      <template v-slot:tbody="{ rows }">
        <tr
          v-for="(row, i) in rows"
          :key="`tr-${i}`"
          :style="row.IsCare ? `background-color: #fcfcaa` : ''"
        >
          <td style="max-width:50px;">
            <a
              href="javascript:"
              class="text-danger"
              v-if="!row['Orders@odata.count'] && rows.length > 1"
              @click="deleteCustomerVisit(row)"
            >
              <CIcon name="cil-trash" />
            </a>
          </td>
          <td>
            {{
              $moment
                .utc(row.CreatedAt)
                .local()
                .format("DD/MM/YY HH:mm")
            }}
          </td>
          <td style="max-width:100px;">
            <span v-if="row.BuyingExp">{{
              $const.BUYING_EXPERIENCES_TEXT[row.BuyingExp]
            }}</span>
          </td>
          <td class="text-truncate" style="max-width:120px;">
            <a
              v-if="isAdmin"
              href="javascript:"
              title="Sửa"
              @click="
                editingRow = row;
                editingSource = row.Source;
                editingNextStep = JSON.parse(row.Details).NextStep;
                editingBuyingExp = row.BuyingExp;
                isEditing = true;
              "
            >
              <CIcon name="cil-pencil" />
            </a>

            <span
              :title="getDetails(row, 'Source')"
              :class="{ 'text-warning': row.CreatedBy != row.UpdatedBy }"
            >
              {{ getDetails(row, "Source") }}
            </span>
          </td>
          <td class="text-truncate" style="max-width:120px;">
            <span :title="getDetails(row, 'Interest')">{{
              getDetails(row, "Interest")
            }}</span>
          </td>
          <td class="text-truncate" style="max-width:120px;">
            <span :title="getDetails(row, 'Comments')">{{
              getDetails(row, "Comments")
            }}</span>
          </td>
          <td v-if="row.Product" class="text-truncate" style="max-width:120px;">
            <span :title="row.Product.Name">{{ row.Product.Name }}</span>
          </td>
          <td v-else />
          <td class="text-truncate" style="max-width:120px;">
            <span :title="getDetails(row, 'PriceRange')">{{
              getDetails(row, "PriceRange")
            }}</span>
          </td>
          <td class="text-truncate" style="max-width:120px;">
            <span :title="getDetails(row, 'NextStep')">{{
              getDetails(row, "NextStep")
            }}</span>
          </td>
          <td class="text-truncate" style="max-width:120px;">
            <span :title="row.Note">{{ row.Note }}</span>
          </td>
          <td class="text-truncate" style="max-width:100px;">
            <span v-if="row.CreatedByName" :title="row.CreatedByName">
              {{ row.CreatedByName }}
            </span>
          </td>
          <td class="text-truncate" style="max-width:120px;">
            <span v-if="row.Agency" :title="row.Agency.Name">
              {{ row.Agency.Name }}
            </span>
          </td>
          <td class="text-truncate" style="max-width:120px;">
            <span :title="getDetails(row, 'SaleProgram')">
              {{ getDetails(row, "SaleProgram") }}
            </span>
          </td>
          <td class="text-truncate" style="max-width:120px;">
            <span :title="getDetails(row, 'KOL')">
              {{ getDetails(row, "KOL") }}
            </span>
          </td>
          <td class="text-truncate" style="max-width:120px;">
            <span :title="`${row.AdGroup} ${row.Advertise}`">
              {{ row.AdGroup }} {{ row.Advertise }}
            </span>
          </td>
          <td class="text-truncate" style="max-width:120px;">
            <span :title="getDetails(row, 'AdEvent')">
              {{ getDetails(row, "AdEvent") }}
            </span>
          </td>
        </tr>
      </template>
    </odata-table>
    <CModal
      title="Sửa ghé thăm/chăm sóc"
      :show.sync="isEditing"
      size="sm"
      centered
    >
      <div class="row">
        <div class="col-md-12">
          <CSelect
            label="Trải nghiệm"
            placeholder="Trải nghiệm"
            :value.sync="editingBuyingExp"
            :options="
              Object.keys($const.BUYING_EXPERIENCES_TEXT).map((_) => {
                return {
                  value: _,
                  label: $const.BUYING_EXPERIENCES_TEXT[_],
                };
              })
            "
          />
        </div>
        <div class="col-md-12">
          <CSelect
            label="Kênh liên hệ"
            placeholder="Kênh liên hệ"
            :value.sync="editingSource"
            :options="
              miscs
                .filter(
                  (m) => m.Type == 'Source' && m.Status == $const.STATUS.Active
                )
                .map((_) => {
                  return {
                    value: _.Value,
                    label: _.Text,
                  };
                })
            "
          />
        </div>
        <div class="col-md-12">
          <CSelect
            label="KQ ghé thăm"
            placeholder="KQ ghé thăm"
            :value.sync="editingNextStep"
            :options="
              miscs
                .filter(
                  (m) =>
                    m.Type == 'NextStep' && m.Status == $const.STATUS.Active
                )
                .map((_) => {
                  return {
                    value: _.Value,
                    label: _.Text,
                  };
                })
            "
          />
        </div>
      </div>
      <template v-slot:footer>
        <CButton
          class="btn btn-primary"
          @click="
            saveCustomerVisit(
              editingRow,
              editingBuyingExp,
              editingSource,
              editingNextStep
            )
          "
          :disabled="saving"
        >
          <CIcon name="cil-save" />
          Lưu
        </CButton>
        <div class="d-inline-block font-italic" v-if="saving">
          <CSpinner color="info" size="sm" class="ml-2" />
          Đang xử lý...
        </div>
      </template>
    </CModal>
    <CModal
      :show.sync="isCreating"
      size="xl"
      :title="isCare ? 'Thêm CSKH' : 'Thêm Ghé Thăm'"
      centered
    >
      <template v-slot:body-wrapper>
        <div
          class="modal-body overflow-auto"
          style="max-height: calc(100vh - 10rem);"
        >
          <customer-visit
            :isCare="isCare"
            :customerId="customerId"
            @close="
              isCreating = false;
              $refs.lstCustVisit.loadData();
            "
          />
        </div>
      </template>
      <template v-slot:footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import customerVisit from "./Visit.vue";

export default {
  name: "VisitList",
  components: {
    odataTable,
    customerVisit,
  },
  props: ["customerId"],
  data() {
    return {
      pageSize: 10,
      miscs: [],
      editingRow: null,
      editingSource: null,
      editingNextStep: null,
      editingBuyingExp: null,
      isEditing: false,
      saving: false,
      isCreating: false,
      isCare: false,
    };
  },
  computed: {
    filter() {
      let filters = [];

      if (this.customerId) {
        filters.push(`CustomerId eq ${this.customerId}`);
      }

      let authUser = this.$user.getters.authUser;
      if (authUser && authUser.role) {
        if (authUser.role == this.$const.ROLES.Agent)
          filters.push(`CreatedBy eq ${authUser.userId}`);
        else if (authUser.role == this.$const.ROLES.Owner) {
          if (authUser.agencyIds && authUser.agencyIds != "all") {
            filters.push(`AgencyId in (${authUser.agencyIds})`);
          }
        } else if (authUser.role == this.$const.ROLES.SuperAdmin)
          filters.push(`CreatedBy gt 0`);
        else filters.push(`CreatedBy eq 0`);
      } else {
        filters.push(`CreatedBy eq 0`);
      }

      return filters.join(" and ");
    },
    isAdmin() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser && authUser.role == this.$const.ROLES.SuperAdmin
        : false;
    },
  },
  async mounted() {
    this.miscs = await this.getMiscList();
  },
  methods: {
    async getMiscList() {
      let typeFilter =
        "Type in ('Source', 'PriceRange', 'Comment', 'Interest', 'NextStep')";
      //let statusFilter = `Status eq ${this.$const.STATUS.Active}`;
      let resp = await this.$http.get(`odata/Misc`, {
        params: {
          $filter: `${typeFilter}`, // and ${statusFilter}`,
          $orderBy: `OrderNo asc`,
        },
      });
      if (resp && resp.status == 200) {
        return resp.data.value;
      }
      return [];
    },
    getDetails(row, field) {
      let result = "";
      let detail = row.Details;
      let miscs = this.miscs;
      if (detail) {
        let objDetail = JSON.parse(detail)[field];
        let value = objDetail ? objDetail : row[field];
        if (value && miscs && miscs.length) {
          let miscItem = miscs.find((_) => _.Value == value);
          result = miscItem ? miscItem.Text : value;
        } else {
          result = value ? value : "";
        }
      }
      return result;
    },

    async processData(data) {
      if (data.value.length) {
        let listCreatedBy = [...new Set(data.value.map((_) => _.CreatedBy))];
        if (listCreatedBy.length) {
          let resp = await this.$http.get(`odata/User`, {
            params: { $filter: `Id in (${listCreatedBy.join()})`, isAll: true },
          });
          if (resp && resp.status == 200) {
            let users = resp.data.value;
            data.value.forEach((_) => {
              let user = users.find((u) => u.Id == _.CreatedBy);
              if (user) {
                _.CreatedByName = user.Name;
              }
            });
          }
        }
      }
      return data.value;
    },

    async deleteCustomerVisit(visit) {
      if (confirm("Bạn chắc chắn muốn xóa ghé thăm này không?")) {
        let resp = await this.$http.patch(`odata/CustomerVisit/${visit.Id}`, {
          Status: this.$const.STATUS.InActive,
        });
        if (resp && resp.status == 204) {
          this.$refs.lstCustVisit.loadData();
        }
      }
    },

    async saveCustomerVisit(visit, buyingExp, source, nextStep) {
      this.saving = true;
      let details = JSON.parse(visit.Details);
      details.NextStep = nextStep;
      let resp = await this.$http.patch(`odata/CustomerVisit/${visit.Id}`, {
        BuyingExp: buyingExp,
        Source: source,
        Details: JSON.stringify(details),
      });
      if (resp && resp.status == 204) {
        this.saving = false;
        this.isEditing = false;
        this.$refs.lstCustVisit.loadData();
      }
    },
  },
};
</script>
