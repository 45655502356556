<template>
  <CModal
    :title="customer.Id ? 'Cập nhật KH' : 'Thêm KH'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="xl"
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow>
          <CCol md="2" class="col-6">
            <CSelect
              label="Danh xưng"
              placeholder="Danh xưng"
              :value.sync="customer.Title"
              :add-input-classes="{
                'is-invalid': !!inValidObject['customer.Title'],
              }"
              :invalid-feedback="inValidObject['customer.Title']"
              :options="
                Object.keys($const.TITLES).map((_) => {
                  return {
                    value: _,
                    label: $const.TITLES[_],
                  };
                })
              "
            />
          </CCol>
          <!-- <CCol md="2" class="col-6">
            <CInput
              label="Họ KH"
              placeholder="Họ KH"
              v-model="customer.LastName"
              :add-input-classes="{
                'is-invalid': !!inValidObject['customer.LastName'],
              }"
              :invalid-feedback="inValidObject['customer.LastName']"
            />
          </CCol> -->
          <CCol md="3">
            <CInput
              label="Tên khách hàng*"
              placeholder="Tên khách hàng"
              v-model="customer.Name"
              :add-input-classes="{
                'is-invalid': !!inValidObject['customer.Name'],
              }"
              :invalid-feedback="inValidObject['customer.Name']"
            />
          </CCol>
          <CCol md="2">
            <CInput
              label="Số điện thoại"
              placeholder="Số điện thoại"
              v-model="customer.Mobile"
              :add-input-classes="{
                'is-invalid': !!inValidObject['customer.Mobile'],
              }"
              :invalid-feedback="inValidObject['customer.Mobile']"
            />
          </CCol>

          <CCol md="2">
            <div role="group" class="form-group">
              <label>
                Ngày sinh
              </label>
              <datetime
                type="date"
                v-model="customer.Dob"
                format="dd/MM/yyyy"
                :input-class="{
                  'form-control': true,
                  'is-invalid': !!inValidObject['customer.Dob'],
                }"
                :class="{
                  'is-invalid': !!inValidObject['customer.Dob'],
                }"
                value-zone="local"
              />
              <div class="invalid-feedback">
                {{ inValidObject["customer.Dob"] }}
              </div>
            </div>
          </CCol>

          <CCol md="3">
            <CInput
              label="Facebook"
              placeholder="Facebook"
              v-model="customer.FacebookLink"
              :add-input-classes="{
                'is-invalid': !!inValidObject['customer.FacebookLink'],
              }"
              :invalid-feedback="inValidObject['customer.FacebookLink']"
            >
            </CInput>
          </CCol>

          <CCol md="3">
            <CInput
              type="email"
              label="Địa chỉ email"
              placeholder="customer@email.com"
              v-model="customer.Email"
              :add-input-classes="{
                'is-invalid': !!inValidObject['customer.Email'],
              }"
              :invalid-feedback="inValidObject['customer.Email']"
            />
          </CCol>

          <CCol md="3">
            <div class="form-group">
              <label>Tỉnh/Thành</label>
              <v-select
                v-model="customer.ProvinceCode"
                :options="provinces"
                :reduce="(p) => p.Code"
                label="Name"
                placeholder="Tỉnh/Thành"
                :class="{
                  'is-invalid border-danger rounded': !!inValidObject[
                    'customer.ProvinceCode'
                  ],
                }"
              />
              <div class="invalid-feedback">
                {{ inValidObject["customer.ProvinceCode"] }}
              </div>
            </div>
          </CCol>
          <CCol md="3">
            <div class="form-group">
              <label>Quận/Huyện</label>
              <v-select
                v-model="customer.DistrictCode"
                :options="districts"
                :reduce="(p) => p.Code"
                label="Name"
                placeholder="Quận/Huyện"
                :class="{
                  'is-invalid border-danger rounded': !!inValidObject[
                    'customer.DistrictCode'
                  ],
                }"
              />
              <div class="invalid-feedback">
                {{ inValidObject["customer.DistrictCode"] }}
              </div>
            </div>
          </CCol>
          <CCol md="3">
            <div class="form-group">
              <label>Phường/Xã</label>
              <v-select
                v-model="customer.WardCode"
                :options="wards"
                :reduce="(p) => p.Code"
                label="Name"
                placeholder="Phường/Xã"
                :class="{
                  'is-invalid border-danger rounded': !!inValidObject[
                    'customer.WardCode'
                  ],
                }"
              />
              <div class="invalid-feedback">
                {{ inValidObject["customer.WardCode"] }}
              </div>
            </div>
          </CCol>
          <CCol md="12">
            <CInput
              label="Địa chỉ*"
              placeholder="Địa chỉ"
              v-model="customer.Address"
              :add-input-classes="{
                'is-invalid': !!inValidObject['customer.Address'],
              }"
              :invalid-feedback="inValidObject['customer.Address']"
            />
          </CCol>
        </CRow>
        <CRow v-if="customerId">
          <CCol md="12">
            <visit-list :customerId="customerId" />
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton class="btn btn-primary" @click="saveCustomer" :disabled="saving">
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import VisitList from "./VisitList.vue";
export default {
  components: { VisitList, Datetime },
  props: ["editing", "customerId"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      customer: {},
      agencies: null,
      saving: false,
      provinces: [],
      districts: [],
      wards: [],
    };
  },
  async mounted() {
    this.provinces = await this.loadProvinces();
  },
  watch: {
    async customerId(val) {
      await this.loadCustomerInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:customerId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },

    async "customer.ProvinceCode"(val) {
      this.districts = await this.loadDistricts(val);
    },
    async "customer.DistrictCode"(val) {
      this.wards = await this.loadWards(val);
    },

    "customer.Mobile"(val) {
      if (val && val.length == 11) {
        alert("Chú ý: Bạn vừa nhập số điện thoại có 11 số!");
      }
    },
  },

  methods: {
    async loadCustomerInfo(customerId) {
      try {
        this.inValidObject = {};
        if (customerId) {
          let filters = [];
          customerId && filters.push(`Id eq ${customerId}`);
          let resp = await this.$http.get(`odata/Customer`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select:
                "Id,Title,Name,LastName,Mobile,Email,FacebookLink,Address,Dob,ProvinceCode,DistrictCode,WardCode",
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.customer = {
              Id: _.Id,
              Title: _.Title,
              Name: _.Name,
              LastName: _.LastName,
              Mobile: _.Mobile,
              Email: _.Email,
              FacebookLink: _.FacebookLink,
              Address: _.Address,
              Dob: _.Dob,
              ProvinceCode: _.ProvinceCode,
              DistrictCode: _.DistrictCode,
              WardCode: _.WardCode,
            };
          }
        } else {
          this.customer = this.newCustomer();
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveCustomer() {
      let customer = this.customer;
      this.inValidObject = this.checkValid(customer);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let custData = {
          Title: customer.Title,
          Name: customer.Name,
          LastName: customer.LastName,
          Mobile: customer.Mobile,
          Email: customer.Email,
          FacebookLink: customer.FacebookLink,
          Address: customer.Address,
          Dob: this.$moment(customer.Dob).toISOString(),
          ProvinceCode: customer.ProvinceCode,
          DistrictCode: customer.DistrictCode,
          WardCode: customer.WardCode,
        };
        // Customer
        if (!customer.Id) {
          // post
          custData.Status = this.$const.STATUS.Active;

          let resp = await this.$http.post(`odata/Customer`, custData);
          if (resp && resp.status == 201) {
            this.$emit("reload");
            this.detailModal = false;
          }
        } else {
          // patch
          let resp = await this.$http.patch(
            `odata/Customer/${customer.Id}`,
            custData
          );
          if (resp && resp.status == 204) {
            this.$emit("reload");
            this.detailModal = false;
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(customer) {
      let inValidObject = {};
      if (!customer.Title)
        inValidObject["customer.Title"] = "Vui lòng chọn Danh xưng!";
      if (!customer.Name)
        inValidObject["customer.Name"] = "Vui lòng nhập Tên khách hàng!";

      if (customer.Mobile) {
        //let mReg = /(0[3|5|7|8|9])+([0-9]{8})\b/g;
        let mReg = /([0]{1}[0-9]{9,10})\b/g;
        let mRes = mReg.test(customer.Mobile);
        if (!mRes) {
          inValidObject["customer.Mobile"] = "Số điện thoại chưa đúng!";
        }
      }
      // if (!customer.Address)
      //   inValidObject["customer.Address"] = "Vui lòng nhập Địa chỉ!";
      // if (!customer.ProvinceCode)
      //   inValidObject["customer.ProvinceCode"] = "Vui lòng nhập Tỉnh/thành!";
      // if (!customer.DistrictCode)
      //   inValidObject["customer.DistrictCode"] = "Vui lòng nhập Quận/huyện!";

      return inValidObject;
    },

    async loadProvinces() {
      let result = [];
      let resp = await this.$http.get("odata/Province", {
        params: { $top: 100, $select: "Code,Name" },
      });
      if (resp && resp.status == 200) {
        result = resp.data.value.map((_) => {
          return {
            Code: _.Code,
            Name: _.Name,
          };
        });
      }
      return result;
    },

    async loadDistricts(provinceCode) {
      let result = [];
      if (provinceCode) {
        let resp = await this.$http.get("odata/District", {
          params: {
            $top: 100,
            $select: "Code,Name",
            $filter: `ProvinceCode eq '${provinceCode}'`,
          },
        });
        if (resp && resp.status == 200) {
          result = resp.data.value.map((_) => {
            return {
              Code: _.Code,
              Name: _.Name,
            };
          });
        }
      }
      return result;
    },

    async loadWards(districtCode) {
      let result = [];
      if (districtCode) {
        let resp = await this.$http.get("odata/Ward", {
          params: {
            $top: 100,
            $select: "Code,Name",
            $filter: `DistrictCode eq '${districtCode}'`,
          },
        });
        if (resp && resp.status == 200) {
          result = resp.data.value.map((_) => {
            return {
              Code: _.Code,
              Name: _.Name,
            };
          });
        }
      }
      return result;
    },

    newCustomer() {
      return {
        Id: 0,
        Title: Object.keys(this.$const.TITLES)[0],
        Name: null,
        LastName: null,
        Mobile: null,
        Email: null,
        FacebookLink: null,
        Address: null,
        Dob: null,
        AgencyId: null,
        ProvinceCode: null,
        DistrictCode: null,
        WardCode: null,
      };
    },
  },
};
</script>
