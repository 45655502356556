<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilGroup" />
          Danh sách khách hàng
        </span>
        <div class="d-inline-block float-right align-right">
          <CButton
            color="info"
            size="sm"
            class="mr-2"
            @click.prevent="
              (e) => {
                showFilter = !showFilter;
              }
            "
          >
            <CIcon v-if="showFilter" name="cil-chevron-top" />
            <CIcon v-else name="cil-chevron-bottom" />
          </CButton>

          <template v-if="authUser && authUser.role == $const.ROLES.SuperAdmin">
            <CSpinner v-if="exporting" color="primary" size="sm" class="mr-2" />
            <CButton
              v-else
              color="primary"
              size="sm"
              class="mr-2"
              title="export"
              @click.prevent="exportXlsx"
            >
              <CIcon name="cib-experts-exchange" />
            </CButton>
          </template>

          <CButton
            color="success"
            size="sm"
            @click.prevent="
              (e) => {
                editing = true;
                customerId = 0;
              }
            "
          >
            <CIcon name="cil-plus" />
            Thêm KH
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <filter-box
          v-if="showFilter"
          @done="
            (e) => {
              filters = e.filters;
              agencyId = e.agencyId;
            }
          "
        />
        <odata-table
          ref="lstCust"
          url="odata/Customer"
          tableClass="table-striped"
          :filter="filter"
          :extraParams="extraParams"
          select="Id,Title,Name,LastName,Mobile,Email,FacebookLink,Address,Status,CreatedAt,ProvinceCode,DistrictCode,WardCode"
          :expand="
            `CustomerVisits($expand=Agency($select=Name),Product($select=Name);$orderby=CreatedAt desc;$filter=${filterCustomerVisit}), ` +
              `ProvinceCodeNavigation($select=Name), ` +
              `DistrictCodeNavigation($select=Name), ` +
              `WardCodeNavigation($select=Name)`
          "
          sortBy="CreatedAt desc"
          :onData="processData"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            Agency: isAgencyFilter
              ? {
                  display: 'Cửa hàng',
                  sortable: false,
                  style: 'min-width: 120px',
                }
              : null,
            Name: {
              field: 'Name',
              display: 'Tên KH',
              sortable: true,
              style: 'min-width: 170px',
            },
            Mobile: {
              field: 'Mobile',
              display: 'Điện thoại',
              sortable: true,
              style: 'min-width: 120px',
            },
            Social: {
              field: 'FacebookLink',
              display: 'Facebook',
              sortable: true,
              style: 'min-width: 120px',
            },
            Address: {
              field: 'Address',
              display: 'Địa chỉ',
              sortable: true,
              style: 'min-width: 200px',
            },
            BuyingExp: {
              display: 'Trải nghiệm',
              style: 'min-width: 120px',
            },
            NextStep: {
              display: 'Kết quả',
              sortable: false,
              style: 'min-width: 150px',
            },
            Source: {
              display: 'Kênh liên hệ',
              sortable: false,
              style: 'min-width: 150px',
            },
            Interest: {
              display: 'Quan tâm',
              sortable: false,
              style: 'min-width: 150px',
            },
            Comments: {
              display: 'Nhận định',
              sortable: false,
              style: 'min-width: 150px',
            },
            Product: {
              display: 'Sản phẩm',
              sortable: false,
              style: 'min-width: 150px',
            },
            PriceRange: {
              display: 'Mức giá quan tâm',
              sortable: false,
              style: 'min-width: 150px',
            },
            CreatedByName: {
              display: 'Nhân viên',
              sortable: false,
              style: 'min-width: 150px',
            },
            CreatedAt: {
              field: 'CreatedAt',
              display: 'Ngày tạo',
              sortable: true,
              style: 'min-width: 120px',
            },
            Note: {
              field: 'Note',
              display: 'Ghi chú',
              sortable: true,
              style: 'min-width: 120px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>{{ i + 1 + ($refs.lstCust.page - 1) * pageSize }}.</td>
              <td v-if="isAgencyFilter">
                <span v-if="row.CustomerVisits.length">
                  {{
                    row.CustomerVisits[0].Agency
                      ? row.CustomerVisits[0].Agency.Name
                      : ""
                  }}
                </span>
              </td>
              <td>
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="`Cập nhật KH: ${row.Name}`"
                  @click="
                    (e) => {
                      customerId = row.Id;
                      editing = true;
                    }
                  "
                >
                  {{ $const.TITLES[row.Title] }}
                  <span v-if="row.LastName">{{ row.LastName }}</span>
                  {{ row.Name }}
                </a>
                <CBadge
                  role="button"
                  color="info"
                  :title="
                    `${
                      row.CustomerVisits
                        ? row.CustomerVisits.filter((_) => !_.IsCare).length
                        : 0
                    } ghé thăm`
                  "
                >
                  {{
                    row.CustomerVisits
                      ? row.CustomerVisits.filter((_) => !_.IsCare).length
                      : 0
                  }}
                </CBadge>
                <CBadge
                  role="button"
                  color="warning"
                  class="ml-1"
                  :title="
                    `${
                      row.CustomerVisits
                        ? row.CustomerVisits.filter((_) => _.IsCare).length
                        : 0
                    } chăm sóc`
                  "
                >
                  {{
                    row.CustomerVisits
                      ? row.CustomerVisits.filter((_) => _.IsCare).length
                      : 0
                  }}
                </CBadge>
              </td>
              <td>{{ row.Mobile }}</td>
              <td class="text-truncate" style="max-width:120px;">
                <span :title="row.FacebookLink">{{ row.FacebookLink }}</span>
              </td>
              <td style="max-width:200px;">
                {{ getAddress(row) }}
              </td>
              <td style="max-width:120px;">
                <span
                  v-if="
                    row.CustomerVisits.length && row.CustomerVisits[0].BuyingExp
                  "
                >
                  {{
                    $const.BUYING_EXPERIENCES_TEXT[
                      row.CustomerVisits[0].BuyingExp
                    ]
                  }}
                </span>
              </td>
              <td>
                <!-- <CBadge :color="getBadge(row.Status)">
                  {{ $const.STATUS_TEXT[row.Status] }}
                </CBadge> -->
                <span
                  v-if="row.CustomerVisits.length"
                  :class="{
                    'text-success font-weight-bold': row.CustomerVisits[0].Details.includes(
                      'Bill'
                    ),
                  }"
                >
                  {{ getDetails(row.CustomerVisits[0], "NextStep") }}
                </span>
              </td>
              <td>
                <span v-if="row.CustomerVisits.length">
                  {{ getDetails(row.CustomerVisits[0], "Source") }}
                </span>
              </td>
              <td>
                <span v-if="row.CustomerVisits.length">{{
                  getDetails(row.CustomerVisits[0], "Interest")
                }}</span>
              </td>
              <td>
                <span v-if="row.CustomerVisits.length">
                  {{ getDetails(row.CustomerVisits[0], "Comments") }}
                </span>
              </td>
              <td>
                <span
                  v-if="
                    row.CustomerVisits.length && row.CustomerVisits[0].Product
                  "
                >
                  {{ row.CustomerVisits[0].Product.Name }}
                </span>
              </td>
              <td>
                <span v-if="row.CustomerVisits.length">
                  {{ getDetails(row.CustomerVisits[0], "PriceRange") }}
                </span>
              </td>
              <td>
                <span v-if="row.CreatedByName">
                  {{ row.CreatedByName }}
                </span>
              </td>
              <td>
                {{
                  $moment
                    .utc(row.CreatedAt)
                    .local()
                    .format("DD/MM/YY HH:mm")
                }}
              </td>
              <td>
                {{
                  row.CustomerVisits.length ? row.CustomerVisits[0].Note : ""
                }}
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :customerId.sync="customerId"
      @reload="$refs.lstCust.loadData()"
    />
  </div>
</template>

<script>
import XLSX from "xlsx";
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";
import filterBox from "./Filter.vue";

export default {
  name: "List",
  components: {
    odataTable,
    detail,
    filterBox,
  },
  data() {
    return {
      editing: false,
      customerId: 0,
      pageSize: 10,
      miscs: [],
      showFilter: false,
      filters: null,
      agencyId: null,
      exporting: false,
    };
  },
  computed: {
    authUser() {
      return this.$user.getters.authUser;
    },

    filter() {
      let filters = [];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }

      return filters.join(" and ");
    },
    filterCustomerVisit() {
      let filters = [];

      let authUser = this.authUser;
      if (authUser && authUser.role) {
        if (authUser.role == this.$const.ROLES.Agent)
          filters.push(`CreatedBy eq ${authUser.userId}`);
        else if (authUser.role == this.$const.ROLES.Owner) {
          if (authUser.agencyIds && authUser.agencyIds != "all") {
            filters.push(`AgencyId in (${authUser.agencyIds})`);
          }
        } else if (authUser.role == this.$const.ROLES.SuperAdmin) {
          if (this.agencyId) {
            filters.push(`AgencyId eq ${this.agencyId}`);
          } else {
            filters.push(`CreatedBy gt 0`);
          }
        } else filters.push(`CreatedBy eq 0`);
      } else {
        filters.push(`CreatedBy eq 0`);
      }

      filters.push(`Status eq ${this.$const.STATUS.Active}`);

      return filters.join(" and ");
    },
    extraParams() {
      if (this.agencyId) {
        return { agencyId: this.agencyId };
      }
      return null;
    },
    isAgencyFilter() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser.role == this.$const.ROLES.SuperAdmin ||
            (authUser.role == this.$const.ROLES.Owner &&
              authUser.agencyIds &&
              authUser.agencyIds.includes(","))
        : false;
    },
  },
  async mounted() {
    this.miscs = await this.getMiscList();
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case this.$const.STATUS.Active:
          return "success";
        case this.$const.STATUS.InActive:
          return "secondary";
        default:
          "primary";
      }
    },

    getAddress(row) {
      return [
        row.Address,
        row.WardCodeNavigation ? row.WardCodeNavigation.Name : "",
        row.DistrictCodeNavigation ? row.DistrictCodeNavigation.Name : "",
        row.ProvinceCodeNavigation ? row.ProvinceCodeNavigation.Name : "",
      ]
        .filter((_) => _)
        .join(", ");
    },

    async setStatus(customer, status) {
      if (
        confirm(
          `Bạn có muốn đổi trạng thái KH này thành ${this.$const.STATUS_TEXT[status]}?`
        )
      ) {
        try {
          let resp = await this.$http.patch(`odata/Customer/${customer.Id}`, {
            Status: status,
          });
          if (resp && resp.status == 204) {
            this.$refs.lstCust.loadData();
          }
        } catch (error) {
          alert(error);
        }
      }
    },

    async getMiscList() {
      let typeFilter =
        "Type in ('Source', 'PriceRange', 'Comment', 'Interest', 'NextStep', 'SaleProgram', 'KOL', 'AdEvent')";
      //let statusFilter = `Status eq ${this.$const.STATUS.Active}`;
      let resp = await this.$http.get(`odata/Misc`, {
        params: {
          $filter: `${typeFilter}`, // and ${statusFilter}`,
        },
      });
      if (resp && resp.status == 200) {
        return resp.data.value;
      }
      return [];
    },

    getDetails(row, field) {
      let result = "";
      let detail = row.Details;
      let miscs = this.miscs;
      if (detail) {
        let objDetail = JSON.parse(detail)[field];
        let value = objDetail ? objDetail : row[field];
        if (value && miscs && miscs.length) {
          let miscItem = miscs.find((_) => _.Value == value);
          result = miscItem ? miscItem.Text : value;
        } else {
          result = value ? value : "";
        }
      }
      return result;
    },

    async processData(data) {
      if (data.value.length) {
        let listCreatedBy = [
          ...new Set(
            data.value
              .filter((_) => _.CustomerVisits.length)
              .map((_) => _.CustomerVisits[0].CreatedBy)
          ),
        ];
        if (listCreatedBy.length) {
          let resp = await this.$http.get(`odata/User`, {
            params: { $filter: `Id in (${listCreatedBy.join()})`, isAll: true },
          });
          if (resp && resp.status == 200) {
            let users = resp.data.value;
            data.value.forEach((_) => {
              if (_.CustomerVisits.length) {
                let user = users.find(
                  (u) => u.Id == _.CustomerVisits[0].CreatedBy
                );
                if (user) {
                  _.CreatedByName = user.Name;
                }
              }
            });
          }
        }
      }
      return data.value;
    },

    async exportXlsx() {
      let oCpnent = this.$refs.lstCust;
      if (!oCpnent.total) {
        alert("Không có dữ liệu!");
        return;
      }

      var createXLSLFormatObj = [];

      /* XLS Head Columns */
      var xlsHeader = [
        "Cửa hàng",
        "Tên KH",
        "Số ghé thăm",
        "Ngày sinh",
        "Điện thoại",
        "Facebook",
        "Địa chỉ",
        "Trải nghiệm",
        "Kết quả",
        "Kênh liên hệ",
        "Quan tâm",
        "Nhận định",
        "Sản phẩm",
        "Mức giá quan tâm",
        "Nhân viên",
        "Ngày tạo",
        "Chương trình Sale",
        "KOL",
        "Quảng cáo",
        "Sự kiện quảng cáo",
      ];

      /* XLS Rows Data */
      var xlsRows = [];

      let pageSize = 100;
      var pages =
        oCpnent.total > pageSize ? Math.ceil(oCpnent.total / pageSize) : 1;

      this.exporting = true;
      for (let page = 1; page <= pages; page++) {
        let params = {
          $select: oCpnent.select,
          $expand: oCpnent.expand,
          $orderby: oCpnent.orderBy,
          $top: pageSize,
          $skip: page ? (page - 1) * pageSize : 0,
        };
        if (oCpnent.filter) {
          params.$filter = oCpnent.filter;
        }
        if (oCpnent.extraParams) {
          Object.keys(oCpnent.extraParams).forEach((_) => {
            params[_] = oCpnent.extraParams[_];
          });
        }
        let resp = await this.$http.get(oCpnent.url, {
          params,
        });
        if (
          resp &&
          resp.status == 200 &&
          resp.data.value &&
          resp.data.value.length
        ) {
          let items = await this.processData(resp.data);
          xlsRows = [
            ...xlsRows,
            ...items.map((row) => {
              let custName = row.LastName
                ? `${row.LastName} ${row.Name}`
                : row.Name;
              return {
                Agency:
                  row.CustomerVisits.length && row.CustomerVisits[0].Agency
                    ? row.CustomerVisits[0].Agency.Name
                    : "",
                Name: `${this.$const.TITLES[row.Title]} ${custName}`,
                VisitCount: row["CustomerVisits@odata.count"],
                Dob: row.Dob
                  ? this.$moment
                      .utc(row.Dob)
                      .local()
                      .format("DD/MM/YYYY")
                  : "",
                Mobile: row.Mobile,
                Social: row.FacebookLink,
                Address: this.getAddress(row),
                BuyingExp:
                  row.CustomerVisits.length && row.CustomerVisits[0].BuyingExp
                    ? this.$const.BUYING_EXPERIENCES_TEXT[
                        row.CustomerVisits[0].BuyingExp
                      ]
                    : "",
                NextStep: row.CustomerVisits.length
                  ? this.getDetails(row.CustomerVisits[0], "NextStep")
                  : "",
                Source: row.CustomerVisits.length
                  ? this.getDetails(row.CustomerVisits[0], "Source")
                  : "",
                Interest: row.CustomerVisits.length
                  ? this.getDetails(row.CustomerVisits[0], "Interest")
                  : "",
                Comments: row.CustomerVisits.length
                  ? this.getDetails(row.CustomerVisits[0], "Comments")
                  : "",
                Product:
                  row.CustomerVisits.length && row.CustomerVisits[0].Product
                    ? row.CustomerVisits[0].Product.Name
                    : "",
                PriceRange: row.CustomerVisits.length
                  ? this.getDetails(row.CustomerVisits[0], "PriceRange")
                  : "",
                CreatedByName: row.CreatedByName ? row.CreatedByName : "",
                CreatedAt: this.$moment
                  .utc(row.CreatedAt)
                  .local()
                  .format("DD/MM/YY HH:mm"),
                SaleProgram: row.CustomerVisits.length
                  ? this.getDetails(row.CustomerVisits[0], "SaleProgram")
                  : "",
                KOL: row.CustomerVisits.length
                  ? this.getDetails(row.CustomerVisits[0], "KOL")
                  : "",
                Advertise: row.CustomerVisits.length
                  ? `${row.CustomerVisits[0].AdGroup} ${row.CustomerVisits[0].Advertise}`
                  : "",
                AdEvent: row.CustomerVisits.length
                  ? this.getDetails(row.CustomerVisits[0], "AdEvent")
                  : "",
              };
            }),
          ];
        }
      }
      this.exporting = false;

      if (!xlsRows.length) {
        alert("Không có dữ liệu!");
        return;
      }

      createXLSLFormatObj.push(xlsHeader);
      xlsRows.forEach((value) => {
        var innerRowData = [];
        Object.keys(value).forEach((p) => {
          innerRowData.push(value[p]);
        });
        createXLSLFormatObj.push(innerRowData);
      });

      /* File Name */
      var filename = `customers_${this.$moment().format("YYYYMMDD")}.xlsx`;

      /* Sheet Name */
      var ws_name = "Customers";

      if (typeof console !== "undefined") console.log(new Date());
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

      /* Add worksheet to workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      /* Write workbook and Download */
      if (typeof console !== "undefined") console.log(new Date());
      XLSX.writeFile(wb, filename);
      if (typeof console !== "undefined") console.log(new Date());
    },
  },
};
</script>
