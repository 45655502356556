var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.customer.Id ? 'Cập nhật KH' : 'Thêm KH',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":"","size":"xl"},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('CRow',[_c('CCol',{staticClass:"col-6",attrs:{"md":"2"}},[_c('CSelect',{attrs:{"label":"Danh xưng","placeholder":"Danh xưng","value":_vm.customer.Title,"add-input-classes":{
              'is-invalid': !!_vm.inValidObject['customer.Title'],
            },"invalid-feedback":_vm.inValidObject['customer.Title'],"options":Object.keys(_vm.$const.TITLES).map(function (_) {
                return {
                  value: _,
                  label: _vm.$const.TITLES[_],
                };
              })},on:{"update:value":function($event){return _vm.$set(_vm.customer, "Title", $event)}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Tên khách hàng*","placeholder":"Tên khách hàng","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['customer.Name'],
            },"invalid-feedback":_vm.inValidObject['customer.Name']},model:{value:(_vm.customer.Name),callback:function ($$v) {_vm.$set(_vm.customer, "Name", $$v)},expression:"customer.Name"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"label":"Số điện thoại","placeholder":"Số điện thoại","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['customer.Mobile'],
            },"invalid-feedback":_vm.inValidObject['customer.Mobile']},model:{value:(_vm.customer.Mobile),callback:function ($$v) {_vm.$set(_vm.customer, "Mobile", $$v)},expression:"customer.Mobile"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Ngày sinh ")]),_c('datetime',{class:{
                'is-invalid': !!_vm.inValidObject['customer.Dob'],
              },attrs:{"type":"date","format":"dd/MM/yyyy","input-class":{
                'form-control': true,
                'is-invalid': !!_vm.inValidObject['customer.Dob'],
              },"value-zone":"local"},model:{value:(_vm.customer.Dob),callback:function ($$v) {_vm.$set(_vm.customer, "Dob", $$v)},expression:"customer.Dob"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customer.Dob"])+" ")])],1)]),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Facebook","placeholder":"Facebook","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['customer.FacebookLink'],
            },"invalid-feedback":_vm.inValidObject['customer.FacebookLink']},model:{value:(_vm.customer.FacebookLink),callback:function ($$v) {_vm.$set(_vm.customer, "FacebookLink", $$v)},expression:"customer.FacebookLink"}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"type":"email","label":"Địa chỉ email","placeholder":"customer@email.com","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['customer.Email'],
            },"invalid-feedback":_vm.inValidObject['customer.Email']},model:{value:(_vm.customer.Email),callback:function ($$v) {_vm.$set(_vm.customer, "Email", $$v)},expression:"customer.Email"}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tỉnh/Thành")]),_c('v-select',{class:{
                'is-invalid border-danger rounded': !!_vm.inValidObject[
                  'customer.ProvinceCode'
                ],
              },attrs:{"options":_vm.provinces,"reduce":function (p) { return p.Code; },"label":"Name","placeholder":"Tỉnh/Thành"},model:{value:(_vm.customer.ProvinceCode),callback:function ($$v) {_vm.$set(_vm.customer, "ProvinceCode", $$v)},expression:"customer.ProvinceCode"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customer.ProvinceCode"])+" ")])],1)]),_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Quận/Huyện")]),_c('v-select',{class:{
                'is-invalid border-danger rounded': !!_vm.inValidObject[
                  'customer.DistrictCode'
                ],
              },attrs:{"options":_vm.districts,"reduce":function (p) { return p.Code; },"label":"Name","placeholder":"Quận/Huyện"},model:{value:(_vm.customer.DistrictCode),callback:function ($$v) {_vm.$set(_vm.customer, "DistrictCode", $$v)},expression:"customer.DistrictCode"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customer.DistrictCode"])+" ")])],1)]),_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Phường/Xã")]),_c('v-select',{class:{
                'is-invalid border-danger rounded': !!_vm.inValidObject[
                  'customer.WardCode'
                ],
              },attrs:{"options":_vm.wards,"reduce":function (p) { return p.Code; },"label":"Name","placeholder":"Phường/Xã"},model:{value:(_vm.customer.WardCode),callback:function ($$v) {_vm.$set(_vm.customer, "WardCode", $$v)},expression:"customer.WardCode"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customer.WardCode"])+" ")])],1)]),_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Địa chỉ*","placeholder":"Địa chỉ","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['customer.Address'],
            },"invalid-feedback":_vm.inValidObject['customer.Address']},model:{value:(_vm.customer.Address),callback:function ($$v) {_vm.$set(_vm.customer, "Address", $$v)},expression:"customer.Address"}})],1)],1),(_vm.customerId)?_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('visit-list',{attrs:{"customerId":_vm.customerId}})],1)],1):_vm._e()],1)]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.saveCustomer}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }