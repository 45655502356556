<template>
  <div>
    <div v-if="isAgencyFilter" class="form-row">
      <CCol md="4">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-4 text-right">Cửa hàng</label>
            <v-select
              v-model="agencyId"
              :options="agencies"
              :reduce="(p) => p.Id"
              label="Name"
              placeholder="Cửa hàng"
              class="col-8"
            />
          </div>
        </div>
      </CCol>
    </div>
    <div class="form-row">
      <CCol md="4">
        <CInput
          label="Tên KH"
          placeholder="Tên khách hàng"
          v-model="customerName"
          :horizontal="{ label: 'col-4 text-right', input: 'col-8' }"
          @keyup.enter="filter"
        />
      </CCol>
      <CCol md="4">
        <CInput
          label="Số ĐT"
          placeholder="Số điện thoại"
          v-model="customerMobile"
          @keyup.enter="filter"
          :horizontal="{ label: 'col-4 text-right', input: 'col-8' }"
        />
      </CCol>
      <CCol md="4">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-4 text-right">Tỉnh/Thành</label>
            <v-select
              v-model="provinceCode"
              :options="provinces"
              :reduce="(p) => p.Code"
              label="Name"
              placeholder="Tỉnh/Thành"
              class="col-8"
            />
          </div>
        </div>
      </CCol>
      <CCol md="4">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-4 text-right">
              Kênh liên hệ
            </label>
            <v-select
              v-model="source"
              :options="
                miscs
                  .filter((_) => _.Type == 'Source')
                  .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                  .map((_) => {
                    return { Id: _.Value, Name: _.Text };
                  })
              "
              :reduce="(p) => p.Id"
              label="Name"
              placeholder="Kênh liên hệ"
              class="col-8"
            />
          </div>
        </div>
      </CCol>
      <CCol md="4">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-4 text-right">
              Kết quả
            </label>
            <v-select
              v-model="nextStep"
              :options="
                miscs
                  .filter((_) => _.Type == 'NextStep')
                  .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                  .map((_) => {
                    return { Id: _.Value, Name: _.Text };
                  })
              "
              :reduce="(p) => p.Id"
              label="Name"
              placeholder="Kết quả"
              class="col-8"
            />
          </div>
        </div>
      </CCol>
      <CCol md="4">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-4 text-right">
              Nhận định
            </label>
            <v-select
              v-model="comment"
              :options="
                miscs
                  .filter((_) => _.Type == 'Comment')
                  .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                  .map((_) => {
                    return { Id: _.Value, Name: _.Text };
                  })
              "
              :reduce="(p) => p.Id"
              label="Name"
              placeholder="Nhận định"
              class="col-8"
            />
          </div>
        </div>
      </CCol>
      <CCol md="4">
        <div role="group" class="form-group form-row">
          <label class="col-4 text-right col-form-label">
            Từ ngày
          </label>
          <div class="col-8">
            <datetime
              type="date"
              v-model="createdFrom"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="4">
        <div role="group" class="form-group form-row">
          <label class="col-4 text-right col-form-label">
            Đến ngày
          </label>
          <div class="col-8">
            <datetime
              type="date"
              v-model="createdTo"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="4" class="text-right">
        <CButton color="primary" class="mr-2 mb-3" @click="filter">
          <CIcon name="cil-search" custom-classes="c-icon m-0" />
          Tìm kiếm
        </CButton>

        <CButton color="secondary" class="mb-3" @click="clear">
          <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
          Đặt lại
        </CButton>
      </CCol>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

export default {
  components: {
    datetime: Datetime,
  },
  data() {
    return {
      customerName: null,
      customerMobile: null,
      provinceCode: null,
      source: null,
      comment: null,
      nextStep: null,
      createdFrom: null,
      createdTo: null,
      agencyId: null,
      provinces: [],
      agencies: [],
      miscs: [],
    };
  },
  computed: {
    isAgencyFilter() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser.role == this.$const.ROLES.SuperAdmin ||
            (authUser.role == this.$const.ROLES.Owner &&
              authUser.agencyIds &&
              authUser.agencyIds.includes(","))
        : false;
    },
  },
  async mounted() {
    this.provinces = await this.loadProvinces();
    if (this.isAgencyFilter) {
      this.agencies = await this.loadAgencies();
    }
    this.miscs = await this.loadMiscs();
  },
  methods: {
    async loadProvinces() {
      let result = [];
      let resp = await this.$http.get("odata/Province", {
        params: { $top: 100, $select: "Code,Name" },
      });
      if (resp && resp.status == 200) {
        result = resp.data.value.map((_) => {
          return {
            Code: _.Code,
            Name: _.Name,
          };
        });
      }
      return result;
    },

    async loadAgencies() {
      let agencies = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Agency", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
          //isAll: true,
        },
      });
      if (resp && resp.status == 200) {
        agencies = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return agencies;
    },

    filter() {
      let filters = [];
      this.customerName &&
        filters.push(`contains(Name, '${this.customerName}')`);
      this.customerMobile &&
        filters.push(`contains(Mobile, '${this.customerMobile}')`);
      this.provinceCode &&
        filters.push(`ProvinceCode eq '${this.provinceCode}'`);

      if (this.createdFrom) {
        let createdFrom = this.$moment(this.createdFrom).toISOString();
        filters.push(`CreatedAt ge ${createdFrom}`);
      }
      if (this.createdTo) {
        let createdTo = this.$moment(this.createdTo)
          .add(1, "days")
          .toISOString();
        filters.push(`CreatedAt lt ${createdTo}`);
      }

      let visitFilters = [];
      this.nextStep &&
        visitFilters.push(
          `contains(c/Details, '"NextStep":"${this.nextStep}"')`
        );
      this.comment &&
        visitFilters.push(
          `contains(c/Details, '"Comments":"${this.comment}"')`
        );
      this.source && visitFilters.push(`c/Source eq '${this.source}'`);

      if (visitFilters && visitFilters.length) {
        filters.push(`CustomerVisits/any(c: ${visitFilters.join(" and ")})`);
      }

      this.$emit("done", { filters, agencyId: this.agencyId });
    },

    clear() {
      this.customerName = null;
      this.customerMobile = null;
      this.provinceCode = null;
      this.agencyId = null;
      this.source = null;
      this.comment = null;
      this.nextStep = null;
      this.createdFrom = null;
      this.createdTo = null;
    },

    async loadMiscs() {
      let types = ["Source", "Comment", "NextStep"];
      let result = [];
      let resp = await this.$http.get("odata/Misc", {
        params: {
          $top: 200,
          $select: "Value,Text,OrderNo,Type",
          $filter: `Status eq ${
            this.$const.STATUS.Active
          } and Type in ('${types.join("','")}')`,
        },
      });
      if (resp && resp.status == 200) {
        result = resp.data.value.map((_) => {
          return {
            Value: _.Value,
            Text: _.Text,
            OrderNo: _.OrderNo,
            Type: _.Type,
          };
        });
      }
      return result;
    },
  },
};
</script>
