var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilGroup"}}),_vm._v(" Danh sách khách hàng ")],1),_c('div',{staticClass:"d-inline-block float-right align-right"},[_c('CButton',{staticClass:"mr-2",attrs:{"color":"info","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
              _vm.showFilter = !_vm.showFilter;
            })($event)}}},[(_vm.showFilter)?_c('CIcon',{attrs:{"name":"cil-chevron-top"}}):_c('CIcon',{attrs:{"name":"cil-chevron-bottom"}})],1),(_vm.authUser && _vm.authUser.role == _vm.$const.ROLES.SuperAdmin)?[(_vm.exporting)?_c('CSpinner',{staticClass:"mr-2",attrs:{"color":"primary","size":"sm"}}):_c('CButton',{staticClass:"mr-2",attrs:{"color":"primary","size":"sm","title":"export"},on:{"click":function($event){$event.preventDefault();return _vm.exportXlsx($event)}}},[_c('CIcon',{attrs:{"name":"cib-experts-exchange"}})],1)]:_vm._e(),_c('CButton',{attrs:{"color":"success","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
              _vm.editing = true;
              _vm.customerId = 0;
            })($event)}}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm KH ")],1)],2)]),_c('CCardBody',[(_vm.showFilter)?_c('filter-box',{on:{"done":function (e) {
            _vm.filters = e.filters;
            _vm.agencyId = e.agencyId;
          }}}):_vm._e(),_c('odata-table',{ref:"lstCust",attrs:{"url":"odata/Customer","tableClass":"table-striped","filter":_vm.filter,"extraParams":_vm.extraParams,"select":"Id,Title,Name,LastName,Mobile,Email,FacebookLink,Address,Status,CreatedAt,ProvinceCode,DistrictCode,WardCode","expand":"CustomerVisits($expand=Agency($select=Name),Product($select=Name);$orderby=CreatedAt desc;$filter=" + _vm.filterCustomerVisit + "), " +
            "ProvinceCodeNavigation($select=Name), " +
            "DistrictCodeNavigation($select=Name), " +
            "WardCodeNavigation($select=Name)","sortBy":"CreatedAt desc","onData":_vm.processData,"pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          Agency: _vm.isAgencyFilter
            ? {
                display: 'Cửa hàng',
                sortable: false,
                style: 'min-width: 120px',
              }
            : null,
          Name: {
            field: 'Name',
            display: 'Tên KH',
            sortable: true,
            style: 'min-width: 170px',
          },
          Mobile: {
            field: 'Mobile',
            display: 'Điện thoại',
            sortable: true,
            style: 'min-width: 120px',
          },
          Social: {
            field: 'FacebookLink',
            display: 'Facebook',
            sortable: true,
            style: 'min-width: 120px',
          },
          Address: {
            field: 'Address',
            display: 'Địa chỉ',
            sortable: true,
            style: 'min-width: 200px',
          },
          BuyingExp: {
            display: 'Trải nghiệm',
            style: 'min-width: 120px',
          },
          NextStep: {
            display: 'Kết quả',
            sortable: false,
            style: 'min-width: 150px',
          },
          Source: {
            display: 'Kênh liên hệ',
            sortable: false,
            style: 'min-width: 150px',
          },
          Interest: {
            display: 'Quan tâm',
            sortable: false,
            style: 'min-width: 150px',
          },
          Comments: {
            display: 'Nhận định',
            sortable: false,
            style: 'min-width: 150px',
          },
          Product: {
            display: 'Sản phẩm',
            sortable: false,
            style: 'min-width: 150px',
          },
          PriceRange: {
            display: 'Mức giá quan tâm',
            sortable: false,
            style: 'min-width: 150px',
          },
          CreatedByName: {
            display: 'Nhân viên',
            sortable: false,
            style: 'min-width: 150px',
          },
          CreatedAt: {
            field: 'CreatedAt',
            display: 'Ngày tạo',
            sortable: true,
            style: 'min-width: 120px',
          },
          Note: {
            field: 'Note',
            display: 'Ghi chú',
            sortable: true,
            style: 'min-width: 120px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return _vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_vm._v(_vm._s(i + 1 + (_vm.$refs.lstCust.page - 1) * _vm.pageSize)+".")]),(_vm.isAgencyFilter)?_c('td',[(row.CustomerVisits.length)?_c('span',[_vm._v(" "+_vm._s(row.CustomerVisits[0].Agency ? row.CustomerVisits[0].Agency.Name : "")+" ")]):_vm._e()]):_vm._e(),_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Cập nhật KH: " + (row.Name))},on:{"click":function (e) {
                    _vm.customerId = row.Id;
                    _vm.editing = true;
                  }}},[_vm._v(" "+_vm._s(_vm.$const.TITLES[row.Title])+" "),(row.LastName)?_c('span',[_vm._v(_vm._s(row.LastName))]):_vm._e(),_vm._v(" "+_vm._s(row.Name)+" ")]),_c('CBadge',{attrs:{"role":"button","color":"info","title":((row.CustomerVisits
                      ? row.CustomerVisits.filter(function (_) { return !_.IsCare; }).length
                      : 0) + " ghé thăm")}},[_vm._v(" "+_vm._s(row.CustomerVisits ? row.CustomerVisits.filter(function (_) { return !_.IsCare; }).length : 0)+" ")]),_c('CBadge',{staticClass:"ml-1",attrs:{"role":"button","color":"warning","title":((row.CustomerVisits
                      ? row.CustomerVisits.filter(function (_) { return _.IsCare; }).length
                      : 0) + " chăm sóc")}},[_vm._v(" "+_vm._s(row.CustomerVisits ? row.CustomerVisits.filter(function (_) { return _.IsCare; }).length : 0)+" ")])],1),_c('td',[_vm._v(_vm._s(row.Mobile))]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"120px"}},[_c('span',{attrs:{"title":row.FacebookLink}},[_vm._v(_vm._s(row.FacebookLink))])]),_c('td',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(_vm.getAddress(row))+" ")]),_c('td',{staticStyle:{"max-width":"120px"}},[(
                  row.CustomerVisits.length && row.CustomerVisits[0].BuyingExp
                )?_c('span',[_vm._v(" "+_vm._s(_vm.$const.BUYING_EXPERIENCES_TEXT[ row.CustomerVisits[0].BuyingExp ])+" ")]):_vm._e()]),_c('td',[(row.CustomerVisits.length)?_c('span',{class:{
                  'text-success font-weight-bold': row.CustomerVisits[0].Details.includes(
                    'Bill'
                  ),
                }},[_vm._v(" "+_vm._s(_vm.getDetails(row.CustomerVisits[0], "NextStep"))+" ")]):_vm._e()]),_c('td',[(row.CustomerVisits.length)?_c('span',[_vm._v(" "+_vm._s(_vm.getDetails(row.CustomerVisits[0], "Source"))+" ")]):_vm._e()]),_c('td',[(row.CustomerVisits.length)?_c('span',[_vm._v(_vm._s(_vm.getDetails(row.CustomerVisits[0], "Interest")))]):_vm._e()]),_c('td',[(row.CustomerVisits.length)?_c('span',[_vm._v(" "+_vm._s(_vm.getDetails(row.CustomerVisits[0], "Comments"))+" ")]):_vm._e()]),_c('td',[(
                  row.CustomerVisits.length && row.CustomerVisits[0].Product
                )?_c('span',[_vm._v(" "+_vm._s(row.CustomerVisits[0].Product.Name)+" ")]):_vm._e()]),_c('td',[(row.CustomerVisits.length)?_c('span',[_vm._v(" "+_vm._s(_vm.getDetails(row.CustomerVisits[0], "PriceRange"))+" ")]):_vm._e()]),_c('td',[(row.CreatedByName)?_c('span',[_vm._v(" "+_vm._s(row.CreatedByName)+" ")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.CreatedAt) .local() .format("DD/MM/YY HH:mm"))+" ")]),_c('td',[_vm._v(" "+_vm._s(row.CustomerVisits.length ? row.CustomerVisits[0].Note : "")+" ")])])})}}])})],1)],1),_c('detail',{attrs:{"editing":_vm.editing,"customerId":_vm.customerId},on:{"update:editing":function($event){_vm.editing=$event},"update:customerId":function($event){_vm.customerId=$event},"update:customer-id":function($event){_vm.customerId=$event},"reload":function($event){return _vm.$refs.lstCust.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }